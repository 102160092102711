<template>
  <div class="help-contanier">
    <div class="content">
      <div class="menu">
        <div
          class="item"
          :class="type==item.type?'active' : ''"
          v-for="(item, index) in menu"
          :key="'about' + index"
          @click="onTab(index)"
        >
          {{ item.name}}
        </div>
      </div>  
      <div class="model">
        <div class="title">{{title}}</div>
        <div class="info" v-if="type=='GYWM'"> 
          <div v-html="systemArticleList.GYWM.content"></div>
        </div>
        <div class="info" v-else>
          <baidu-map :style="{width:map.width,height:map.height}" class="map" ak="Vp7e0G4U0MaP0s31xnMfNdn41sSBw4e5" :zoom="map.zoom" :center="{lng: map.center.lng, lat: map.center.lat}" @ready="handler" :scroll-wheel-zoom="true">
            <!--比例尺控件-->
            <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
            <!--缩放控件-->
            <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" ></bm-navigation>
          </baidu-map>
          <div class="contact">
            <div class="word">联系电话：400-633-8088</div>
            <div class="word">联系邮箱：artuns@qq.com</div>
            <div class="word">联系地址：浙江省杭州市拱墅区海蓝天行国际</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmScale from 'vue-baidu-map/components/controls/Scale'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
export default {
  components: {
    BaiduMap,
    BmScale,
    BmNavigation,
  },
  data() {
    return {
      type: this.$route.query.type?this.$route.query.type:'LXWM',
      menu: [{
          type:'GYWM',
          name:"关于我们"
        },{
        type:'LXWM',
        name:"联系我们"
        }],
      title:'',
      map:{
        center: {lng: 120.112428,lat:30.310366},
        zoom: 12,
        width:'840px',
        height:'510px'
      },
      systemArticleList:[]
    };
  },
  created() {
    this.getTitle();
    this.getSystemArticleList()
  },
  methods: {
    getSystemArticleList(){
      this.$http.get('/api/web/index/getSystemArticleList').then(res=>{
        this.systemArticleList=res.data.systemArticleList
      })
    },
     //地图初始化
    handler ({BMap, map}) {
        var point = new BMap.Point(120.112428, 30.310366)
        map.centerAndZoom(point, 13)
        var marker = new BMap.Marker(point) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
        var opts = {
          position: new BMap.Point(120.112428, 30.310366), // 指定文本标注所在的地理位置
          offset: new BMap.Size(-80, -80) // 设置文本偏移量
        };
        var label = new BMap.Label("杭州银山科技有限公司<span class='angle'><span>", opts);
        // 自定义文本标注样式
        label.setStyle({
          color: "#fff",
          backgroundColor: "#333333",
          border: "0",
          fontSize : "16px",
          width:"180px",
          opacity:"0.8",
          verticalAlign:"center",
          borderRadius: "5px",
          whiteSpace:"normal",
          wordWrap:"break-word",
          padding:"10px",
        });
        map.addOverlay(label);
    },
    onTab(i){
      this.type=this.menu[i].type;
      this.getTitle();
    },
    getTitle(){
      let index=this.menu.findIndex(item=>this.type==item.type)
      this.title=this.menu[index].name;
    }
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>

<style lang='less' scoped>
.help-contanier {
  padding: 30px 0 90px;
  min-height: 600px;
  background: #f5f5f5;
  /deep/.angle{
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom:-60px;
    border: 14px solid;
    left: 68px;
    bottom: -28px;
    opacity: 0.8;
    border-color: #000 transparent transparent transparent;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    .menu {
      display: flex;
      flex-direction: column;
      width: 240px;
      padding: 10px 0;
      margin-bottom: 20px;
      background: #fff;
      .item {
        widows: 100%;
        height: 75px;
        line-height: 75px;
        font-size: 16px;
        color: #666;
        text-align: center;
        cursor: pointer;
        &.active {
          color: #d71a18;
        }
      }
    }
    .model {
      flex: 1;
      width: 920px;
      margin-left: 40px;
      background: #fff;
      .title {
        height: 70px;
        line-height: 70px;
        padding: 0 40px;
        text-align: left;
        font-size: 16px;
        color: #333;
        border-bottom: 1px solid #f5f5f5;
      }
      .info {
        min-height: 550px;
        padding: 30px 40px;
        color: #333;
        text-align: left;        
        .txt{
          margin-bottom: 20px;
          text-indent: 28px;
          line-height: 30px;
        }
        .contact{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;
          height: 60px;
          background: #f5f5f5;
          .word{
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
